/*!
   * PatternFly Elements: PFElement 2.4.5
   * @license
   * Copyright 2020 Red Hat, Inc.
 * 
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 * 
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
 * SOFTWARE.
 * 
*/

import 'ie-array-find-polyfill';
import 'string-includes-polyfill';
import { autoReveal } from './reveal.js';
import {
  PropAndAttr,
  WrappedPropAndAttr,
  NumberPropAndAttr,
  AliasPropAndAttr,
  BooleanPropAndAttr,
  FloatPropAndAttr,
  FunctionPropAndAttr,
  IntPropAndAttr,
  ObjectPropAndAttr,
  StringPropAndAttr,
} from './props-and-attrs';
const prefix = 'pfe-';

function kebabToCamelCase(str) {
  let arr = str.split('-');
  let capital = arr.map((item, index) => (index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()));
  // ^-- change here.
  return capital.join('');
}

class PFElement extends HTMLElement {
  static create(pfe) {
    window.customElements.define(pfe.tag, pfe);
  }

  /**
   *
   * @returns {PropAndAttr[]}
   */
  static get propsAndAttrs() {
    const analyticsPropsAndAttrs = this.analyticsEvents.map((originalEventName) => {
      const attributeName = this.getAnalyticsAttributeName(originalEventName);
      const propName = kebabToCamelCase(attributeName);
      return new this.propsAndAttrsAPI.string(attributeName, propName);
    });
    const propsAndAttrs = analyticsPropsAndAttrs;
    if (this.analyticsEvents.length) {
      propsAndAttrs.push(new this.propsAndAttrsAPI.boolean('analytics-off', 'analyticsOff'));
    }
    return propsAndAttrs;
  }

  static get propsAndAttrsAPI() {
    return {
      alias: AliasPropAndAttr,
      boolean: BooleanPropAndAttr,
      float: FloatPropAndAttr,
      function: FunctionPropAndAttr,
      integer: IntPropAndAttr,
      number: NumberPropAndAttr,
      object: ObjectPropAndAttr,
      string: StringPropAndAttr,
      wrapped: WrappedPropAndAttr,
    };
  }

  propsAndAttrsConstructorLogic() {
    this._pfeClass.propsAndAttrs.forEach((propAndAttrInstance) => propAndAttrInstance.instanceConstructorLogic(this));
  }

  propsAndAttrsOnAttributeChangedLogic(attrName, oldValue, newValue) {
    const foundElements = this._pfeClass.propsAndAttrs.filter((propAndAttrInstance) => propAndAttrInstance.attrName === attrName);
    if (foundElements.length) {
      if (foundElements.length > 1) {
        throw new Error('more than one attribute with the same name has been defined in propAndAttr api');
      }
      foundElements[0].onAttributeChangedLogic(this, oldValue, newValue);
    }
  }

  static get propAndAttrsObservedAttributes() {
    return this.propsAndAttrs
      .filter((propAndAttrInstance) => propAndAttrInstance.attrIsObserved)
      .map((propAndAttrInstance) => propAndAttrInstance.attrName);
  }

  static debugLog(preference = null) {
    if (preference !== null) {
      PFElement._debugLog = !!preference;
    }
    return PFElement._debugLog;
  }

  static log(...msgs) {
    if (PFElement.debugLog()) {
      console.log(...msgs);
    }
  }

  static get PfeTypes() {
    return {
      Container: 'container',
      Content: 'content',
      Combo: 'combo',
    };
  }

  static get version() {
    return '{{version}}';
  }

  static get observedAttributes() {
    return ['pfe-theme', ...this.propAndAttrsObservedAttributes];
  }

  static get analyticsEvents() {
    return [];
  }

  static getAnalyticsAttributeName(originalEventName) {
    return 'analytics-' + originalEventName;
  }

  static getAnalyticsPropName(originalEventName) {
    return kebabToCamelCase(this.getAnalyticsAttributeName(originalEventName));
  }

  get randomId() {
    return Math.random().toString(36).substr(2, 9);
  }

  get version() {
    return this._pfeClass.version;
  }

  get pfeType() {
    return this.getAttribute(`${prefix}type`);
  }

  set pfeType(value) {
    this.setAttribute(`${prefix}type`, value);
  }

  cssVariable(name, value, element = this) {
    name = name.substr(0, 2) !== '--' ? '--' + name : name;
    if (value) {
      element.style.setProperty(name, value);
    }
    return window.getComputedStyle(element).getPropertyValue(name).trim();
  }

  // Returns a single element assigned to that slot; if multiple, it returns the first
  has_slot(name) {
    return this.querySelector(`[slot='${name}']`);
  }

  // Returns an array with all elements assigned to that slot
  has_slots(name) {
    return [...this.querySelectorAll(`[slot='${name}']`)];
  }

  // Update the theme context for self and children
  context_update() {
    const children = this.querySelectorAll('[pfelement]');
    let theme = this.cssVariable('theme');

    // Manually adding `pfe-theme` overrides the css variable
    if (this.hasAttribute('pfe-theme')) {
      theme = this.getAttribute('pfe-theme');
      // Update the css variable to match the data attribute
      this.cssVariable('theme', theme);
    }

    // Update theme for self
    this.context_set(theme);

    // For each nested, already upgraded component
    // set the context based on the child's value of --theme
    // Note: this prevents contexts from parents overriding
    // the child's context should it exist
    [...children].map((child) => {
      if (child.connected) {
        child.context_set(theme);
      }
    });
  }

  // Get the theme variable if it exists, set it as an attribute
  context_set(fallback) {
    let theme = this.cssVariable('theme');
    if (!theme) {
      theme = this.getAttribute('pfe-theme');
    }
    if (!theme && fallback) {
      theme = fallback;
    }
    if (theme) {
      this.setAttribute('on', theme);
    }
  }

  constructor(pfeClass, { type = null, delayRender = false, customRendering = false } = {}) {
    super();

    this.connected = false;
    this._pfeClass = pfeClass;
    this.tag = pfeClass.tag;
    this.props = pfeClass.properties;
    this.slots = pfeClass.slots;
    this._queue = [];

    if (type) {
      this._queueAction({
        type: 'setProperty',
        data: {
          name: 'pfeType',
          value: type,
        },
      });
    }

    this._connectedCallback = this._connectedCallback.bind(this);
    this._observer = new MutationObserver(this._connectedCallback); // not used yet, just created

    if (!customRendering) {
      this.template = document.createElement('template');

      this.log(`Constructing...`);

      if (this.tag !== 'pfe-table') {
        this.attachShadow({ mode: 'open' });
      }

      if (
        !delayRender &&
        (this.tag !== 'pfe-navigation' ||
          this.tag !== 'pfe-navigation-main' ||
          this.tag !== 'pfe-navigation-item' ||
          this.tag !== 'pfe-card')
      ) {
        if (!delayRender) {
          this.log(`Render...`);
          this.render();
          this.log(`Rendered.`);
        }
      } else {
        if (this.tag !== 'pfe-table') {
          this.shadowRoot.innerHTML = `<slot></slot>`;
        }
      }
    }

    this.propsAndAttrsConstructorLogic();
    this.directionConstructorLogic();
  }

  connectedCallback() {
    if (
      this.tag === 'pfe-navigation' ||
      this.tag === 'pfe-navigation-main' ||
      this.tag === 'pfe-navigation-item' ||
      this.tag === 'pfe-card'
    ) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (this.children.length) {
            this.render();
            this._connectedCallback();
            return resolve();
          }

          this._observer.observe(this, { childList: true });
        }, 0);
      });
    } else {
      this._connectedCallback();
    }
  }

  _connectedCallback() {
    this.connected = true;
    this.log(`Connecting...`);

    if (this.tag !== 'pfe-table' && window.ShadyCSS) {
      this.log(`Styling...`);
      window.ShadyCSS.styleElement(this);
      // this.temporaryFixIE11EdgeSlotted();
      this.log(`Styled.`);
    }

    // Throw a warning if the on attribute was manually added before upgrade
    if (!this.hasAttribute('pfelement') && this.hasAttribute('on')) {
      console.warn(
        `${this.tag}${
          this.id ? `[#${this.id}]` : ``
        }: The "on" attribute is protected and should not be manually added to a component. The base class will manage this value for you on upgrade.`
      );
    }

    // @TODO maybe we should use just the attribute instead of the class?
    // https://github.com/angular/angular/issues/15399#issuecomment-318785677
    this.classList.add('PFElement');
    this.setAttribute('pfelement', '');

    if (typeof this.props === 'object') {
      this._mapSchemaToProperties(this.tag, this.props);
      this.log(`Properties attached.`);
    }

    if (typeof this.slots === 'object') {
      this._mapSchemaToSlots(this.tag, this.slots);
      this.log(`Slots attached.`);
    }

    if (this._queue.length) {
      this._processQueue();
    }

    // Initialize the on attribute if a theme variable is set
    // do not update the on attribute if a user has manually added it
    // then trigger an update in nested components
    this.context_update();

    this.directionConnectedCallbackLogic();

    this.log(`Connected.`);
  }

  temporaryFixIE11EdgeSlotted() {
    // ENABLE THIS PATCH WHEN SLOT ARE NOT SUPPORTED
    const tagName = `${this.tag}`;
    const getStyleTag = document.head.querySelector(`[scope^=${tagName}]`);

    if (getStyleTag) {
      let style = Array.from(document.styleSheets).find((item) => {
        if (item.ownerNode && item.ownerNode.getAttribute('scope').indexOf(tagName) !== -1) {
          return item;
        }
        return null;
      });
      if (style) {
        let dynamicSlot = '';
        Array.from(style.cssRules || style.rules || []).forEach((rule) => {
          if (rule.selectorText && typeof rule.cssText === 'string' && rule.selectorText.indexOf('slot') > -1) {
            dynamicSlot += ' ' + rule.cssText.replace(/>/gi, '');
          } else if (rule && typeof rule.cssText === 'string') {
            dynamicSlot += ' ' + rule.cssText;
          }
        });
        if (dynamicSlot) {
          getStyleTag.innerText = dynamicSlot;
        }
      }
    }
  }

  disconnectedCallback() {
    this._observer.disconnect();
    this.log(`Disconnecting...`);

    this.connected = false;

    this.directionDisconnectedCallbackLogic();

    this.log(`Disconnected.`);
  }

  attributeChangedCallback(attr, oldVal, newVal) {
    if (this._pfeClass.cascadingAttributes) {
      const cascadeTo = this._pfeClass.cascadingAttributes[attr];
      if (cascadeTo) {
        this._copyAttribute(attr, cascadeTo);
      }

      if (attr === 'pfe-theme') {
        this.context_update();
      }
    }

    this.propsAndAttrsOnAttributeChangedLogic(attr, oldVal, newVal);
  }

  _copyAttribute(name, to) {
    const recipients = [...this.querySelectorAll(to), ...this.shadowRoot.querySelectorAll(to)];
    const value = this.getAttribute(name);
    const fname = value == null ? 'removeAttribute' : 'setAttribute';
    for (const node of recipients) {
      node[fname](name, value);
    }
  }

  // Map the imported properties json to real props on the element
  // @notice static getter of properties is built via tooling
  // to edit modify src/element.json
  _mapSchemaToProperties(tag, properties) {
    this.log('Mapping properties...');
    // Loop over the properties provided by the schema
    Object.keys(properties).forEach((attr) => {
      let data = properties[attr];

      // Only attach the information if the data provided is a schema object
      if (typeof data === 'object') {
        // Prefix default is true
        let hasPrefix = true;
        let attrName = attr;
        // Set the attribute's property equal to the schema input
        this[attr] = data;
        // Initialize the value to null
        this[attr].value = null;

        if (typeof this[attr].prefixed !== 'undefined') {
          hasPrefix = this[attr].prefixed;
        }

        if (hasPrefix) {
          attrName = `${prefix}${attr}`;
        }

        // If the attribute exists on the host
        if (this.hasAttribute(attrName)) {
          // Set property value based on the existing attribute
          this[attr].value = this.getAttribute(attrName);
        }
        // Otherwise, look for a default and use that instead
        else if (data.default) {
          const dependency_exists = this._hasDependency(tag, data.options);
          const no_dependencies = !data.options || (data.options && !data.options.dependencies.length);
          // If the dependency exists or there are no dependencies, set the default
          if (dependency_exists || no_dependencies) {
            this.setAttribute(attrName, data.default);
            this[attr].value = data.default;
          }
        }
      }
    });

    this.log('Properties mapped.');
  }

  // Test whether expected dependencies exist
  _hasDependency(tag, opts) {
    // Get any possible dependencies for this attribute to exist
    let dependencies = opts ? opts.dependencies : [];
    // Initialize the dependency return value
    let hasDependency = false;
    // Check that dependent item exists
    // Loop through the dependencies defined
    for (let i = 0; i < dependencies.length; i += 1) {
      const slot_exists = dependencies[i].type === 'slot' && this.has_slots(`${tag}--${dependencies[i].id}`).length > 0;
      const attribute_exists = dependencies[i].type === 'attribute' && this.getAttribute(`${prefix}${dependencies[i].id}`);
      // If the type is slot, check that it exists OR
      // if the type is an attribute, check if the attribute is defined
      if (slot_exists || attribute_exists) {
        // If the slot does exist, add the attribute with the default value
        hasDependency = true;
        // Exit the loop
        break;
      }
    }
    // Return a boolean if the dependency exists
    return hasDependency;
  }

  // Map the imported slots json
  // @notice static getter of properties is built via tooling
  // to edit modify src/element.json
  _mapSchemaToSlots(tag, slots) {
    this.log('Validate slots...');
    // Loop over the properties provided by the schema
    Object.keys(slots).forEach((slot) => {
      let slotObj = slots[slot];

      // Only attach the information if the data provided is a schema object
      if (typeof slotObj === 'object') {
        let slotExists = false;
        let result = [];
        // If it's a named slot, look for that slot definition
        if (slotObj.namedSlot) {
          // Check prefixed slots
          result = this.has_slots(`${tag}--${slot}`);
          if (result.length > 0) {
            slotObj.nodes = result;
            slotExists = true;
          }

          // Check for unprefixed slots
          result = this.has_slots(`${slot}`);
          if (result.length > 0) {
            slotObj.nodes = result;
            slotExists = true;
          }
          // If it's the default slot, look for direct children not assigned to a slot
        } else {
          result = [...this.children].filter((child) => !child.hasAttribute('slot'));

          if (result.length > 0) {
            slotObj.nodes = result;
            slotExists = true;
          }
        }

        // If the slot exists, attach an attribute to the parent to indicate that
        if (slotExists) {
          this.setAttribute(`has_${slot}`, '');
        } else {
          this.removeAttribute(`has_${slot}`);
        }
      }
    });
    this.log('Slots validated.');
  }

  _queueAction(action) {
    this._queue.push(action);
  }

  _processQueue() {
    this._queue.forEach((action) => {
      this[`_${action.type}`](action.data);
    });

    this._queue = [];
  }

  _setProperty({ name, value }) {
    this[name] = value;
  }

  // @TODO This is a duplicate function to cssVariable above, combine them
  static var(name, element = document.body) {
    return window.getComputedStyle(element).getPropertyValue(name).trim();
  }

  var(name) {
    return PFElement.var(name, this);
  }

  render() {
    this.shadowRoot.innerHTML = '';
    this.template.innerHTML = this.html;

    if (window.ShadyCSS) {
      window.ShadyCSS.prepareTemplate(this.template, this.tag);
    }

    this.shadowRoot.appendChild(this.template.content.cloneNode(true));
  }

  log(...msgs) {
    PFElement.log(`[${this.tag}]`, ...msgs);
  }

  emitEvent(name, { bubbles = true, cancelable = false, composed = false, detail = {} } = {}) {
    this.log(`Custom event: ${name}`);
    return this.dispatchEvent(
      new CustomEvent(name, {
        bubbles,
        cancelable,
        composed,
        detail,
      })
    );
  }

  getUniqueId(prefix = 'id') {
    let id = prefix;
    for (let i = 1; document.getElementById(id); i++) {
      id = prefix + '-' + i;
    }
    return id;
  }

  getAnalyticsLocalEvent(originalEventName) {
    return this[this.constructor.getAnalyticsPropName(originalEventName)] || originalEventName;
  }

  /**
   *
   * @param {string} analyticsEventKey key of the object analyticsEvents
   * @param {object} customParameters an object with extra informations
   */
  analyticsLog(originalEventName, customParameters = {}) {
    if (!this.analyticsOff) {
      const eventName = this.getAnalyticsLocalEvent(originalEventName);
      const detail = {
        customParameters,
        element: this,
        elementId: this.id,
        elementTag: this.tag,
        eventName,
        originalEventName,
        url: window.location.href,
      };
      document.dispatchEvent(
        new CustomEvent('pfe-analytics', {
          bubbles: false,
          cancelable: false,
          composed: false,
          detail,
        })
      );
    }
  }

  /**
   * A unified logic for all components in the page for special configurations needed when the 'dir' attribute
   * is set to rtl. Changing the attribute in the html will update all components in the page.
   * All added components will also follow the same configuration.
   *
   * This part of the logic is the creation of the mutationObserver and is done in the constructor
   */
  directionConstructorLogic() {
    this.directionObserver = new MutationObserver(() => {
      this.dir = document.body.parentElement.dir;
    });
  }

  /**
   * This part of the direction logic initialize the observer and set the starting value of the attribute for the component.
   * It is done in the connectedCallback
   */
  directionConnectedCallbackLogic() {
    this.directionObserver.observe(document.body.parentElement, { attributes: true, attributeFilter: ['dir'] });
    this.dir = document.body.parentElement.dir;
  }

  /**
   * This part of the direction logic disconnect the observer when the element is removed from the page
   */
  directionDisconnectedCallbackLogic() {
    this.directionObserver.disconnect();
  }
}

autoReveal(PFElement.log);

export default PFElement;
