function parseFunction(fn) {
  try {
    if (typeof fn === 'string') {
      return new Function(`return ${fn}`)();
    } else if (typeof fn === 'function') {
      return fn;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export const propsAndAttrsAPI = {
  object: {
    prop: {
      setter: function (privatePropName, value, onChange) {
        const oldValue = this[privatePropName];
        this[privatePropName] = value;
        if (onChange) onChange(this, oldValue, value);
      },
      getter: function (privatePropName) {
        return this[privatePropName];
      },
      define: function (propName, privatePropName, onChange) {
        if (!privatePropName) {
          privatePropName = '_' + propName;
        }
        this[privatePropName] = {};
        Object.defineProperty(this, propName, {
          set: (v) => {
            propsAndAttrsAPI.object.prop.setter.bind(this)(privatePropName, v, onChange);
          },
          get: () => propsAndAttrsAPI.object.prop.getter.bind(this)(privatePropName),
          enumerable: true,
        });
      },
    },
    attr: {
      parse: function (stringValue) {
        return JSON.parse(stringValue);
      },
      onChange: function (propName, attrName, oldValue, newValue) {
        this[propName] = propsAndAttrsAPI.object.attr.parse.bind(this)(newValue);
      },
    },
  },
  alias: {
    prop: {
      setter: function (aliasedPropName, value) {
        this[aliasedPropName] = value;
      },
      getter: function (aliasedPropName) {
        return this[aliasedPropName];
      },
      define: function (propName, aliasedPropName) {
        Object.defineProperty(this, propName, {
          set: (v) => propsAndAttrsAPI.alias.prop.setter.bind(this)(aliasedPropName, v),
          get: () => propsAndAttrsAPI.alias.prop.getter.bind(this)(aliasedPropName),
          enumerable: true,
        });
      },
    },
  },
  boolean: {
    prop: {
      setter: function (attrName, value) {
        if (value) {
          this.setAttribute(attrName, '');
        } else {
          this.removeAttribute(attrName);
        }
      },
      getter: function (attrName) {
        return this.hasAttribute(attrName) && this.getAttribute(attrName) !== 'false';
      },
      define: function (propName, attrName) {
        Object.defineProperty(this, propName, {
          set: (v) => propsAndAttrsAPI.boolean.prop.setter.bind(this)(attrName, v),
          get: () => propsAndAttrsAPI.boolean.prop.getter.bind(this)(attrName),
          enumerable: true,
        });
      },
    },
    attr: {
      onChange: function (oldValue, newValue, onChangeCallback) {
        // check if the value went from being there to not being there and vice-versa, and if there is an actual function to call
        if (onChangeCallback && propsAndAttrsAPI.boolean.attr.hasChanged(oldValue, newValue)) {
          onChangeCallback(this, oldValue, newValue);
        }
      },
      hasChanged: (oldValue, newValue) => {
        const oldValueIsFalse = oldValue == null || oldValue === 'false';
        const newValueIsFalse = newValue == null || newValue === 'false';
        return oldValueIsFalse !== newValueIsFalse;
      },
    },
  },
  string: {
    prop: {
      setter: function (attrName, value) {
        if (value != null) {
          this.setAttribute(attrName, value);
        } else {
          this.removeAttribute(attrName);
        }
      },
      getter: function (attrName) {
        if (this.hasAttribute(attrName)) {
          return this.getAttribute(attrName);
        }
        return null;
      },
      define: function (propName, attrName) {
        Object.defineProperty(this, propName, {
          set: (v) => propsAndAttrsAPI.string.prop.setter.bind(this)(attrName, v),
          get: () => propsAndAttrsAPI.string.prop.getter.bind(this)(attrName),
          enumerable: true,
        });
      },
    },
  },
  function: {
    prop: {
      // mostly like for object
      setter: function (privatePropName, value, onChange) {
        const oldValue = this[privatePropName];
        this[privatePropName] = value;
        if (onChange) onChange(this, oldValue, value);
      },
      getter: function (privatePropName) {
        return this[privatePropName];
      },
      define: function (propName, privatePropName, onChange) {
        if (!privatePropName) {
          privatePropName = '_' + propName;
        }
        this[privatePropName] = null;
        Object.defineProperty(this, propName, {
          set: (v) => {
            propsAndAttrsAPI.function.prop.setter.bind(this)(privatePropName, v, onChange);
          },
          get: () => propsAndAttrsAPI.function.prop.getter.bind(this)(privatePropName),
          enumerable: true,
        });
      },
    },
    attr: {
      parse: (value) => parseFunction(value),
      onChange: function (propName, attrName, oldValue, newValue) {
        this[propName] = propsAndAttrsAPI.function.attr.parse.bind(this)(newValue);
      },
    },
  },
  number: {
    prop: {
      setter: function (attrName, value) {
        if (value != null && !isNaN(value)) {
          this.setAttribute(attrName, value);
        } else {
          this.removeAttribute(attrName);
        }
      },
    },
  },
};

export class PropAndAttr {
  constructor(type, attrName, propName, onChangeCallback) {
    this.type = type;
    this.attrName = attrName;
    this.propName = propName;
    this.onChangeCallback = onChangeCallback;
    if (!attrName && !propName) {
      throw new Error('attribute name and property name are not defined. You need to define at least one');
    }
  }

  get attrIsObserved() {
    // to be checked
    return this.attrName && this.onChangeCallback;
  }

  /**
   *
   * @param {PFElement} pfeInstance
   */
  instanceConstructorLogic(pfeInstance) {
    if (this.propName) {
      this.defineProp(pfeInstance);
    }
  }

  /**
   *
   * @param {PFElement} pfeInstance
   */
  getDefineFn(pfeInstance) {
    return propsAndAttrsAPI[this.type].prop.define.bind(pfeInstance);
  }

  /**
   *  to do: redo for each prop type / class
   * @param {PFElement} pfeInstance
   */
  defineProp(pfeInstance) {
    this.getDefineFn(pfeInstance)(this.propName);
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    this.onChangeCallback(pfeInstance, oldValue, newValue);
  }
}

export class ObjectPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, onChangeCallback, privatePropName) {
    super('object', attrName, propName, onChangeCallback);
    if (this.propName) {
      this.privatePropName = privatePropName || '_' + this.propName;
    }
  }

  /**
   *
   * @param {PFElement} pfeInstance
   */
  defineProp(pfeInstance) {
    this.getDefineFn(pfeInstance)(this.propName, this.privatePropName, this.onChangeCallback);
  }

  get attrIsObserved() {
    return !!this.attrName;
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    if (this.propName) {
      propsAndAttrsAPI.object.attr.onChange.bind(pfeInstance)(this.propName, this.attrName, oldValue, newValue);
    }
  }
}

export class StringPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, onChangeCallback, privatePropName) {
    super('string', attrName, propName, onChangeCallback);
    if (!this.attrName) {
      this.privatePropName = privatePropName || '_' + this.propName;
    }
  }

  /**
   *
   * @param {PFElement} pfeInstance
   */
  defineProp(pfeInstance) {
    if (this.attrName) {
      this.getDefineFn(pfeInstance)(this.propName, this.attrName);
    } else {
      Object.defineProperty(pfeInstance, this.propName, {
        set: (v) => {
          const oldValue = pfeInstance[this.privatePropName];
          pfeInstance[this.privatePropName] = v;
          if (this.onChangeCallback) {
            this.onChangeCallback(pfeInstance, oldValue, v);
          }
        },
        get: () => pfeInstance[this.privatePropName],
        enumerable: true,
      });
    }
  }
}

export class AliasPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, aliasedPropName, aliasedAttrName) {
    super('alias', attrName, propName, undefined);
    this.aliasedPropName = aliasedPropName;
    this.aliasedAttrName = aliasedAttrName;
    if (!aliasedAttrName && !aliasedAttrName) {
      throw new Error('Aliased property has no aliasedPropName and aliasedAttrName');
    }
  }

  /**
   *
   * @param {PFElement} pfeInstance
   */
  defineProp(pfeInstance) {
    if (this.aliasedPropName) {
      this.getDefineFn(pfeInstance)(this.propName, this.aliasedPropName);
    } else if (this.aliasedAttrName) {
      Object.defineProperty(pfeInstance, this.propName, {
        set: (v) => {
          pfeInstance.setAttribute(this.aliasedAttrName, v);
        },
        get: () => pfeInstance.hasAttribute(this.aliasedAttrName) && pfeInstance.getAttribute(this.aliasedAttrName),
        enumerable: true,
      });
    }
  }

  get attrIsObserved() {
    return !!this.attrName;
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    if (this.aliasedAttrName) {
      pfeInstance.setAttribute(this.aliasedAttrName, newValue);
    } else if (this.aliasedPropName) {
      pfeInstance[this.aliasedPropName] = newValue;
    }
  }
}

export class BooleanPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, onChangeCallback, privatePropName) {
    super('boolean', attrName, propName, onChangeCallback);
    if (!attrName) {
      this.privatePropName = privatePropName || '_' + propName;
    }
  }

  defineProp(pfeInstance) {
    if (this.attrName) {
      this.getDefineFn(pfeInstance)(this.propName, this.attrName);
    } else {
      Object.defineProperty(pfeInstance, this.propName, {
        set: (v) => {
          const oldValue = pfeInstance[this.privatePropName];
          pfeInstance[this.privatePropName] = !!v;
          if (this.onChangeCallback) {
            this.onChangeCallback(pfeInstance, oldValue, v);
          }
        },
        get: () => pfeInstance[this.privatePropName],
        enumerable: true,
      });
    }
  }
}

export class FunctionPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, onChangeCallback, privatePropName) {
    super('function', attrName, propName, onChangeCallback);
    if (!propName || typeof propName !== 'string') {
      throw new Error('propName must be a non-empty string for functions');
    }
    this.privatePropName = privatePropName || '_' + propName;
  }

  defineProp(pfeInstance) {
    this.getDefineFn(pfeInstance)(this.propName, this.privatePropName, this.onChangeCallback);
  }

  get attrIsObserved() {
    return !!this.attrName;
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    propsAndAttrsAPI.function.attr.onChange.bind(pfeInstance)(this.propName, this.attrName, oldValue, newValue);
  }
}

export class NumberPropAndAttr extends PropAndAttr {
  constructor(type, attrName, propName, onChangeCallback, privatePropName) {
    super(type, attrName, propName, onChangeCallback);
    if (!attrName || privatePropName) {
      this.privatePropName = privatePropName || '_' + propName;
    }
  }

  parse(value) {
    return Number(value);
  }

  defineProp(pfeInstance) {
    if (this.privatePropName && this.attrName) {
      throw new Error('Not implemented yet for ' + this.type + ' prop and attribute');
    } else if (this.attrName) {
      Object.defineProperty(pfeInstance, this.propName, {
        set: (v) => {
          propsAndAttrsAPI.number.prop.setter.bind(pfeInstance)(this.attrName, v);
        },
        get: () => {
          if (pfeInstance.hasAttribute(this.attrName)) {
            return this.parse(pfeInstance.getAttribute(this.attrName));
          }
        },
        enumerable: true,
      });
    } else {
      Object.defineProperty(pfeInstance, this.propName, {
        set: (v) => {
          const oldValue = pfeInstance[this.privatePropName];
          pfeInstance[this.privatePropName] = v;
        },
        get: () => pfeInstance[this.privatePropName],
        enumerable: true,
      });
    }
  }

  get attrIsObserved() {
    return super.attrIsObserved || (this.privatePropName && this.attrName);
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    if (this.privatePropName && this.attrName) {
      throw new Error('Not implemented yet for ' + this.type + ' prop and attribute');
    } else {
      const oldNum = this.parse(oldValue);
      const newNum = this.parse(newValue);
      this.onChangeCallback(pfeInstance, oldNum, newNum);
    }
  }
}

export class IntPropAndAttr extends NumberPropAndAttr {
  constructor(attrName, propName, onChangeCallback) {
    super('integer', attrName, propName, onChangeCallback);
  }

  parse(value) {
    return parseInt(value);
  }
}

export class FloatPropAndAttr extends NumberPropAndAttr {
  constructor(attrName, propName, onChangeCallback) {
    super('float', attrName, propName, onChangeCallback);
  }

  parse(value) {
    return parseFloat(value);
  }
}

const errorWrapperElementNotDefined = new Error(
  'The wrapped element is not yet obtainable. The property must be set in a different way, or after the wrapped element is obtainable'
);

export class WrappedPropAndAttr extends PropAndAttr {
  constructor(attrName, propName, onChangeCallback, getWrappedElementFn, wrappedAttrName, wrappedPropName) {
    super('wrapped', attrName, propName, onChangeCallback);
    if (typeof getWrappedElementFn !== 'function') {
      throw new Error('getWrappedElementFn must be a function that returns an html element for wrapped prop and attr type');
    }
    if (!wrappedAttrName && !wrappedPropName) {
      throw new Error('one of wrappedAttrName and wrappedPropName must be defined, for wrapped prop and attr type');
    }
    this.getWrappedElementFn = getWrappedElementFn;
    this.wrappedAttrName = wrappedAttrName;
    this.wrappedPropName = wrappedPropName;
  }

  updateAttrFromWrappedAttribute(pfeInstance) {
    const wrappedEl = this.getWrappedElementFn(pfeInstance);
    if (wrappedEl) {
      if (wrappedEl.hasAttribute(this.wrappedAttrName)) {
        pfeInstance.setAttribute(this.attrName, wrappedEl.getAttribute(this.wrappedAttrName));
      } else {
        pfeInstance.removeAttribute(this.attrName);
      }
    } else {
      throw errorWrapperElementNotDefined;
    }
  }

  updateWrappedAttribute(pfeInstance, value) {
    const wrappedEl = this.getWrappedElementFn(pfeInstance);
    if (wrappedEl) {
      if (value != null) {
        wrappedEl.setAttribute(this.wrappedAttrName, value);
      } else {
        wrappedEl.removeAttribute(this.wrappedAttrName);
      }
    } else {
      throw errorWrapperElementNotDefined;
    }
  }

  defineProp(pfeInstance) {
    const attributes = { enumerable: true };

    if (this.wrappedPropName) {
      attributes.set = (v) => {
        const wrappedEl = this.getWrappedElementFn(pfeInstance);
        if (wrappedEl) {
          const oldValue = pfeInstance[this.propName];
          wrappedEl[this.wrappedPropName] = v;
          if (this.wrappedAttrName && this.attrName) {
            this.updateAttrFromWrappedAttribute(pfeInstance);
          } else if (this.onChangeCallback) {
            this.onChangeCallback(pfeInstance, oldValue, pfeInstance[this.propName]);
          }
        } else {
          // this could be implemented in the future
          throw errorWrapperElementNotDefined;
        }
        // to do! add onchange somewhere
      };
      attributes.get = () => {
        const wrappedEl = this.getWrappedElementFn(pfeInstance);
        if (wrappedEl) {
          return wrappedEl[this.wrappedPropName];
        } else {
          throw errorWrapperElementNotDefined;
        }
      };
    } else {
      // wrappedAttrName

      attributes.set = (v) => {
        const wrappedEl = this.getWrappedElementFn(pfeInstance);
        if (wrappedEl) {
          const oldValue = pfeInstance[this.propName];
          this.updateWrappedAttribute(pfeInstance, v);
          if (this.attrName) {
            this.updateAttrFromWrappedAttribute(pfeInstance);
          } else if (this.onChangeCallback) {
            this.onChangeCallback(pfeInstance, oldValue, pfeInstance[this.propName]);
          }
        } else {
          throw errorWrapperElementNotDefined;
        }
      };
      attributes.get = () => {
        const wrappedEl = this.getWrappedElementFn(pfeInstance);
        if (wrappedEl) {
          if (wrappedEl.hasAttribute(this.wrappedAttrName)) {
            return wrappedEl.getAttribute(this.wrappedAttrName);
          }
        } else {
          throw errorWrapperElementNotDefined;
        }
      };
    }

    Object.defineProperty(pfeInstance, this.propName, attributes);
  }

  get attrIsObserved() {
    return !!this.attrName;
  }

  onAttributeChangedLogic(pfeInstance, oldValue, newValue) {
    const wrapperEl = this.getWrappedElementFn(pfeInstance);
    if (wrapperEl) {
      if (oldValue !== newValue) {
        if (this.wrappedAttrName) {
          this.updateWrappedAttribute(pfeInstance, newValue);
          this.updateAttrFromWrappedAttribute(pfeInstance);
        } else if (this.propName) {
          pfeInstance[this.propName] = newValue;
        }
        if (this.onChangeCallback) {
          this.onChangeCallback(pfeInstance, oldValue, newValue);
        }
      }
    } else {
      throw errorWrapperElementNotDefined;
    }
  }
}
